import rallax from '../../node_modules/rallax.js/dist/rallax';
import { tns } from "tiny-slider/src/tiny-slider";

if (module.hot) {
  module.hot.accept();
}

function likesCounter(node) {
  let count = 0;
  const interval = setInterval(() => {
    count++;

    if (count >= 2390) {
      clearInterval(interval); // If exceeded 100, clear interval
    }
    node.innerHTML = count;
  }, 0);
}

window.onbeforeunload = () => {
  window.scrollTo(0, 0);
};


window.onload = () => {

  const slider = tns({
    container: '.phone-slider',
    items: 1,
    slideBy: 'page',
    autoplay: true,
    controls: false,
    speed: 300,
    autoplayTimeout: 2000,
    center: true,
  });

  const iconBig = document.querySelectorAll('.prBig');
  const iconMid = document.querySelectorAll('.prMid');
  const iconSm = document.querySelectorAll('.prSm');
  const titles = document.querySelectorAll('.pr2');
  let likes = false;

  [...iconBig].forEach((i) => {
    rallax(i, { speed: 0.05 }).start();
  });

  [...iconMid].forEach((i) => {
    rallax(i, { speed: 0.03 }).start();
  });

  [...iconSm].forEach((i) => {
    rallax(i, { speed: 0.02 }).start();
  });

  [...titles].forEach((i) => {
    rallax(i, { speed: 0.08 }).start();
  });
  window.onscroll = () => {
    const box = document.querySelector('.img-folder');
    const likesBox = document.querySelector('.step-3');
    if (box.getBoundingClientRect().top < (window.innerHeight / 2)) {
      box.classList.add('show');
    }
    if (likesBox.getBoundingClientRect().top < (window.innerHeight / 2)) {
      if (!likes){
        likesCounter(document.querySelector('.likes-count'));
        likes = true;
      }
    }
  };
};
